import React, { useState } from "react";
import FramerAnimate from "components/FramerAnimate";

import FramerOpacity from "components/FramerOpacity";
import Testimonial from "./components/Testimonial";
import ankur from "assets/Ankur.jpg";
import Lin from "assets/Lin.jpeg";
import Chuanbao from "assets/Chuanbao.jpeg";

const Testimonials = () => {
  const [comments, setComments] = useState([
    {
      id: 1,
      name: "Ankur S.",
      comment:
        "Sajjan did a great job on the ARK railings web application. He is dedicated, and hard-working and delivers quality software.",
      linkName: "ARK Railings",
      link: "https:www.arkrailings.com",
      src: ankur,
    },
    {
      id: 2,
      name: "Chuanbao L.",
      comment:
        "Sajjan worked in my team for about 4 months in 2014. He performed very well in his development job. He demonstrated very good leaning and problem solving ability. Sajjan is also a hard worker who has very good can-do attitude. I highly recommend Sajjan to any future employers. Technologies he used: Spring framework, Spring MVC, Oracle database, JSP, JQuery, Tomcat, Maven, Hudson, Jira, Agile, etc. ",
      linkName: "LinkedIn",
      link: "https://www.linkedin.com/in/chuanbao/",
      src: Chuanbao,
    },
    {
      id: 3,
      name: "Lin L.",
      comment:
        "Sajjan is a dedicated, responsible and technically sound individual. He has a good understanding of java and web technology. During the last co-op term, he got into productivity very quickly, fulfilled assigned tasks timely and professionally. He is always ready to put all his energy and time to get the job done. He is a hard working and very serious team player, and certainly can get my full recommendation.",
      linkName: "LinkedIn",
      link: "https://www.linkedin.com/in/lin-li-2432b966/",
      src: Lin,
    },
  ]);

  return (
    <div name="testimonial" className="w-full bg-[#191919]">
      <FramerOpacity>
        <div className="text-5xl max-w-[1240px] m-auto font-bold p-8 text-[#191919] drop-shadow-[0px_2px_3px_rgb(255,255,255)]">
          <FramerAnimate>
            <p>Testimonials</p>
          </FramerAnimate>
        </div>

        <div className="max-w-[1240px] flex flex-col md:flex-row gap-8 py-6  justify-center items-center m-auto min-h-screen">
          <Testimonial comments={comments} />
        </div>
      </FramerOpacity>
    </div>
  );
};

export default Testimonials;
