import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";

const CardBox = ({ projects }) => {
  const handleClick = (website) => {
    window.location.assign(website);
  };
  return (
    <>
      {projects &&
        projects?.map((item, index) => (
          <Card
            sx={{
              maxWidth: 325,
              m: 2,
              "&:hover": {
                scale: "1.1",
              },
            }}
            key={index}
          >
            <CardMedia component="img" image={item.image} title="projects" />
            <CardContent>
              <Button
                variant="outlined"
                onClick={() => handleClick(item.website)}
                sx={{
                  borderRadius: ".375rem",
                  background: "rgb(237, 151,79)",
                  borderColor: "rgb(237, 151,79)",
                  color: "#212A3E",
                  mb: 2,
                  "&:hover": {
                    boxShadow: "0px 3px 10px #000",
                    scale: "1.1",
                    color: "#212A3E",
                    background: "#F1F6F9",
                    borderColor: "#F1F6F9",
                    fontWeight: 700,
                  },
                }}
              >
                {item.name}
              </Button>

              <Typography variant="body2" sx={{ color: "#212A3E", fontSize: "12px" }}>
                {item.description}
              </Typography>
              <br></br>
              <div className="flex items-center flex-wrap gap-1">
                {item.stack.map((i, index) => (
                  <div
                    key={index}
                    className="text-xs border p-1 rounded-md bg-slate-600 text-[#fff]"
                  >
                    {i}
                  </div>
                ))}
              </div>
              <div></div>
            </CardContent>
          </Card>
        ))}
    </>
  );
};

export default CardBox;
