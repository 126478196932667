import React, { useEffect, useRef } from "react";
import { motion, useAnimation, useInView } from "framer-motion";

const FramerDisplay = ({ children }) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const mainControls = useAnimation();

  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
  }, [isInView]);
  return (
    <>
      <div ref={ref}>
        <motion.div
          variants={{
            hidden: { opacity: 0, y: 90, x: 80 },
            visible: { opacity: 1, y: 0, x: 0 },
          }}
          initial="hidden"
          animate={mainControls}
          transition={{ duration: 1.5, ease: "easeInOut", delay: 0.2 }}
        >
          {children}
        </motion.div>
      </div>
    </>
  );
};

export default FramerDisplay;
