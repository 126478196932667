import React from "react";
import FramerAnimate from "components/FramerAnimate";
import { Paper, Typography } from "@mui/material";
import { QuestionAnswer } from "@mui/icons-material";
import FramerDisplay from "components/FramerDisplay";
import { blue, grey, orange } from "@mui/material/colors";

const Faq = () => {
  const faqs = [
    { question: "Location", answer: "Based in Victoria, BC." },
    {
      question: "Proud of",
      answer:
        "I take pride in developing web applications that help other people in their day-to-day lives.",
    },
    {
      question: "Mission",
      answer: "Help small businesses grow in a fast-moving digital world.",
    },
    {
      question: "My values",
      answer:
        "Integrity, trust, reliability, and team player are my values regarding software development.",
    },

    {
      question: "Strengths/Weaknesses",
      answer:
        "I can do better by learning while focusing on only one thing at a time.",
    },

    {
      question: "Software Development",
      answer:
        "I'm a one-man army in freelancing projects, so I take full responsibility for my software architecture.",
    },
  ];
  return (
    <div name="faq" className="w-full bg-[#191919]">
      <FramerAnimate>
        <div className="text-5xl max-w-[1240px] m-auto font-bold p-8 text-[#191919] drop-shadow-[0px_2px_3px_rgb(255,255,255)]">
          <p>FAQs</p>
        </div>
      </FramerAnimate>
      <div className="max-w-[1240px] flex items-center m-auto min-h-screen">
        <div className="grid grid-rows-2 grid-flow-row sm:grid-flow-col items-center gap-x-11 gap-y-11 p-4 ">
          {faqs.map((item, index) => (
            <Paper
              key={index}
              elevation={12}
              sx={{ maxWidth: "100%", height: "100%", background: "#191919" }}
              className="hover:scale-110 cursor-pointer"
            >
              <div className="p-4">
                <FramerDisplay>
                  <Typography
                    variant="subtitle1"
                    sx={{ color: grey[500], pb: 2, fontWeight: 500 }}
                  >
                    <QuestionAnswer className="text-lime-400" /> {item.question}
                  </Typography>
                </FramerDisplay>

                <Typography variant="body2" sx={{ color:  grey[700] }}>
                  {item.answer}
                </Typography>
              </div>
            </Paper>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Faq;
