import { Visibility } from "@mui/icons-material";
import axios from "axios";
import React, { useEffect, useState } from "react";

export const Footer = () => {
  const year = new Date().getFullYear();
  const [pageVisitInfo, setPageVisitInfo] = useState({});

  useEffect(() => {
    if (sessionStorage.getItem("visit") === null) {
      updatePageViewCount("type=newPageVisit");
      sessionStorage.setItem("visit", "Hello");
    } else {
      updatePageViewCount("type=pageVisit");
    }
  }, []);

  const updatePageViewCount = async (type) => {
    try {
      const response = await axios.get("/api/getVisitInfo?" + type, {
        headers: {
          "Content-type": "application/json;charset=UTF-8",
        },
      });

      if (response.status === 200) {
        setPageVisitInfo(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div name="contact" className="w-full bg-[#191919]">
      <div className="items-center max-w-[1240px] m-auto h-full text-[#9e9e9e] ">
        <div className="items-center w-full h-full  flex flex-col justify-center">
          <div className="flex w-full items-center text-stone-600 justify-center px-2 text-xs ">
            <span className="px-1 ">{pageVisitInfo.newPageVisit}</span>
            <span>
              <Visibility sx={{ fontSize: "13pt" }} />
            </span>
          </div>
        </div>
        <div className="text-[8pt] py-2 text-center w-full">
          {year} &copy; Sajjan Shrestha
        </div>
      </div>
    </div>
  );
};
