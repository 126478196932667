import { useScroll, useTransform, motion } from "framer-motion";
import React, { useRef } from "react";

const FramerOpacity = ({ children }) => {
  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ["end end", "end start"],
  });

  const opacity = useTransform(scrollYProgress, [0, 0.6], [1, 0]);
  return (
    <motion.div ref={targetRef} style={{ opacity }}>
      {children}
    </motion.div>
  );
};

export default FramerOpacity;
