import React, { useEffect, useState } from "react";
import { Modal, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import axios from "axios";
import { Backdrop, CircularProgress } from "@mui/material";
import AlertMsg from "./AlertMsg";


const ModalBox = ({ open, setOpen }) => {
  const [form, setForm] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [openBackDrop, setOpenBackDrop] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState("");
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setTimeout(() => {
      setNotificationMsg("");
    }, 4000);
  }, [notificationMsg]);

  const handleInputChange = (e) => {
    setErrors({});
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    setOpenBackDrop(true);
    sendMessgae(form);
  };

  const sendMessgae = async (form) => {
    try {
      const res = await axios.post("/api/sendMessage", form, {
        headers: {
          "content-type": "application/json",
        },
      });
      if (res.status === 200) {
        setOpenBackDrop(false);
        setNotificationMsg(res.data);
        setForm({ ...form, name: "", email: "", message: "" });
      }
    } catch (error) {
      setOpenBackDrop(false);
      if (error.response.status === 400) {
        if (error.response.data instanceof Object)
          setErrors(error.response?.data);
        else {
          setErrors({ ...errors, msg: error.response?.data });
        }
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
    setForm({ ...form, name: "", email: "", message: "" });
    setErrors({});
  };
  return (
    <>
      <Modal
        open={open}
        onClose={(e, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <p className="text-xl font-bold text-center text-gray-700">
            <span className="">Message Details</span>
          </p>

          {notificationMsg && (
            <AlertMsg status={"success"} message={notificationMsg} />
          )}

          {errors?.msg && <AlertMsg status={"error"} message={errors?.msg} />}

          <div className="flex flex-col  justify-center items-center">
            <div className="w-full mt-4">
              <form noValidate onSubmit={onSubmit}>
                <div>
                  <TextField
                    id="nameId"
                    label="Name"
                    fullWidth
                    value={form.name}
                    name="name"
                    color="success"
                    autoComplete="off"
                    variant="outlined"
                    onChange={(e) => handleInputChange(e)}
                  />
                  <p className="text-red-500 text-xs">{errors.name}</p>
                </div>
                <div className=" py-2">
                  <TextField
                    id="emailId"
                    label="Email"
                    fullWidth
                    value={form.email}
                    name="email"
                    color="success"
                    autoComplete="off"
                    variant="outlined"
                    onChange={(e) => handleInputChange(e)}
                  />
                  <p className="text-red-500 text-xs">{errors.email}</p>
                </div>

                <div>
                  <TextField
                    id="messageId"
                    label="Message"
                    fullWidth
                    multiline
                    value={form.message}
                    name="message"
                    color="success"
                    autoComplete="off"
                    variant="outlined"
                    rows={3}
                    onChange={(e) => handleInputChange(e)}
                  />
                  <p className="text-red-500 text-xs">{errors.message}</p>
                </div>
                <div className="flex w-full justify-end p-5">
                  <button
                    type="button"
                    className="border p-1 w-[50%] bg-orange-400  text-gray-800 rounded-md cursor-pointer hover:text-red-50 
              "
                    onClick={handleClose}
                  >
                    Cancel
                  </button>
                  &nbsp;
                  <button
                    type="submit"
                    className="border text-gray-800 p-1 w-[50%] bg-orange-400 rounded-md  cursor-pointer hover:text-lime-50  
             "
                  >
                    Send
                  </button>
                  <Backdrop
                    sx={{
                      color: "#fff",
                      zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open={openBackDrop}
                  >
                    <CircularProgress color="inherit" />
                  </Backdrop>
                </div>
              </form>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default ModalBox;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  borderRadius: ".5rem",
  p: 4,
};
