import React from "react";
import { Typography } from "@mui/material";

const Testimonial = ({ comments }) => {
  return (
    <>
      {comments &&
        comments.map((item, index) => (
          <div
            key={index}
            className=" p-2 w-[350px] h-[500px] bg-[#F1F6F9] rounded-md drop-shadow-[0px_2px_8px_rgb(25,25,25)] "
          >
            <div className="flex items-center justify-center p-4">
              <a href={item.link} target="_blank">
                <img
                  src={item.src}
                  loading="lazy"
                  width={150}
                  className="hover:scale-110 cursor-pointer rounded-full  drop-shadow-[0px_0px_8px_rgb(25,25,25)]"
                />
              </a>
            </div>
            <div className="p-4  text-[#212A3E]">
              <div className="text-center ">
               
                  {item.name}
                
              </div>
              <Typography variant="body2" sx={{ py: 2, fontSize:"13px" }}>
                {item.comment}
              </Typography>
            </div>
          </div>
        ))}
    </>
  );
};

export default Testimonial;
