import React, { useRef, useState } from "react";
import Java from "assets/javaLogo.webp";
import saz from "assets/saz.webp";
import { Email, GitHub, LinkedIn } from "@mui/icons-material";
import ModalBox from "components/ModalBox";
import { Tooltip } from "@mui/material";
import Display from "components/FramerDisplay";
import FramerAnimate from "components/FramerAnimate";
import { useScroll, useTransform, motion } from "framer-motion";
import FramerOpacity from "components/FramerOpacity";
import ubc from 'assets/ubc-logo.png'
import master_degree from 'assets/ms.webp'
import Js from 'assets/js.png'

const About = () => {
  const [open, setOpen] = useState(false);

  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ["start end", "end start"],
  });

  const scale = useTransform(
    scrollYProgress,
    [0.1, 0.2, 0.3, 0.5, 0.7],
    [0.1, 0.5, 1, 1.1, 1.15]
  );

  return (
    <div name="about" className="w-full bg-[#191919] ">
      <FramerOpacity>
        <div className="max-w-[1240px] m-auto text-5xl font-bold px-8 pt-8 text-[#191919] drop-shadow-[0px_2px_3px_rgb(255,255,255)]">
          <FramerAnimate>
            <p>About</p>
          </FramerAnimate>
        </div>

        <div
          ref={targetRef}
          className="items-center max-w-[1240px] m-auto min-h-screen flex"
        >
          <div className="flex flex-col sm:flex-row justify-center items-center h-full">
            <div className="min-w-[50%] ">
              <div className="">
                <div
                  className="animate-[move1_30s_ease-in-out_infinite] font-bold p-4 
               text-center items-center flex justify-center text-[#191919]  text-2xl drop-shadow-[0_0px_3px_rgb(163,230,53)]"
                >
                  Trust
                </div>

                <div
                  className="animate-[move2_30s_ease-in-out_infinite]  font-bold text-[#191919] 
                drop-shadow-[0_0px_3px_rgb(163,230,53)]  text-2xl p-4  text-center items-center flex justify-center "
                >
                  Integrity
                </div>

                <div className="flex justify-center m-2">
                  <motion.img
                    style={{ scale }}
                    src={saz}
                    loading="lazy"
                    alt="profile_pic"
                    width={250}
                    className="rounded-2xl"
                  />
                </div>

                <div
                  className="animate-[move3_30s_ease-in-out_infinite] p-4   font-bold   text-[#191919] drop-shadow-[0_0px_3px_rgb(163,230,53)] 
               text-2xl text-center items-center flex justify-center "
                >
                  Team Player
                </div>

                <div
                  className="animate-[move4_30s_ease-in-out_infinite] font-bold  p-4  text-[#191919] drop-shadow-[0_0px_3px_rgb(163,230,53)] 
              text-2xl text-center items-center flex justify-center "
                >
                  Reliable
                </div>
              </div>
            </div>

            <div
              ref={targetRef}
              className="w-full flex flex-col justify-center items-center"
            >
              <div className="px-2">
                <p className="text-3xl text-center  lg:text-4xl text-stone-50 px-4 ">
                  <span>Hi! I'm </span>
                  <span className="text-lime-500 font-bold overflow-hidden">
                    Sajjan Shrestha
                  </span>
                </p>

                <p className="text-justify text-gray-400 p-4 text-sm overflow-hidden">
                  I am a Java/JS enthusiast and an experienced software
                  developer who likes to build web applications using new trends
                  and technologies. I seek skills so that I can learn how to do
                  things in the right way and the best way to achieve this is by
                  keep learning.
                </p>
                <div className="flex justify-center items-center rounded-md  bg-[#F1F6F9]/80 gap-2">
                  <div className="flex flex-col items-center justify-center gap-1">
                    <div>
                      <img src={ubc} alt="UBC" width={65} />
                    </div>
                  </div>
                  <div className="flex flex-col items-center justify-center">
                    <div>
                      <img src={master_degree} alt="UBC" width={60} />
                    </div>
                    <div className="text-[#212A3E]  text-xs">
                      Master's Degree
                    </div>
                  </div>
                </div>

                <Display>
                  <p
                    className="text-lg md:text-2xl px-4 py-2 md:font-mono items-start h-full flex justify-center gap-2
                  italic text-orange-500 overflow-hidden"
                  >
                    <span className="relative top-4">
                      Chasing skills everyday with
                    </span>
                    <span className="">
                      <img
                        src={Java}
                        width={35}
                        loading="lazy"
                        alt="Java"
                        className="animate-pulse"
                      />
                    </span>
                    <span className="">
                      <img
                        src={Js}
                        width={50}
                        loading="lazy"
                        alt="Javascript"
                        className="animate-pulse"
                      />
                    </span>
                  </p>
                </Display>
              </div>

              <div className="flex items-center py-4 min-w-[50%] justify-center gap-4">
                <a
                  onClick={() => setOpen(true)}
                  className="cursor-pointer text-sky-500"
                >
                  <Tooltip title="Contact">
                    <Email
                      fontSize="large"
                      className="animate-bounce duration-1000"
                    />
                  </Tooltip>
                </a>
                <a
                  href="https://www.linkedin.com/in/sajjanshres"
                  target="_blank"
                  className="cursor-pointer text-sky-500"
                >
                  <Tooltip title="Linkedin">
                    <LinkedIn
                      fontSize="large"
                      className="animate-bounce duration-1000"
                    />
                  </Tooltip>
                </a>
                <a
                  href="https://github.com/sajjanshres"
                  target="_blank"
                  className="cursor-pointer text-sky-500"
                >
                  <Tooltip title="GitHub">
                    <GitHub
                      fontSize="large"
                      className="animate-bounce duration-1000"
                    />
                  </Tooltip>
                </a>

                <ModalBox open={open} setOpen={setOpen} />
              </div>
            </div>
          </div>
        </div>
      </FramerOpacity>
    </div>
  );
};

export default About;
