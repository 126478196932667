import React from "react";
import ReactPlayer from "react-player";

import thumbnail from "assets/thumbnail.jpg";
import { Paper } from "@mui/material";
import FramerOpacity from "components/FramerOpacity";

export const Dashboard = () => {
  const fileName = "myIntro.mp4";
  return (
    <>
      <div name="home" className="w-full bg-[#191919]">
        <FramerOpacity>
          <div className="items-center max-w-[1240px] min-h-screen m-auto flex ">
            <div className="w-full">
              <Paper
                elevation={20}
                sx={{
                  mt: 11,
                  mb: 11,
                  boxShadow: "0px 0px 4rem rgb(163, 230, 53, .2)",
                  borderRadius: "1rem",
                }}
              >
                <div className="flex flex-col justify-center">
                  <ReactPlayer
                    url={`/api/video/${fileName}`}
                    playing={true}
                    controls={true}
                    width={"100%"}
                    height={"100%"}
                    light={
                      <img
                        src={thumbnail}
                        width="100%"
                        loading="lazy"
                        alt="Thumbnail"
                        className="rounded-3xl"
                      />
                    }
                    config={{
                      wistia: {
                        playerId: "1",
                      },
                    }}
                  />
                </div>
              </Paper>
            </div>
          </div>
        </FramerOpacity>
      </div>
    </>
  );
};
