import {
  AccountTree,
  Home,
  InfoOutlined,
  Laptop,
  GitHub,
  LinkedIn,
  Reviews,
  Quiz,
} from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import React, { useState } from "react";
import { Link, animateScroll as scroll } from "react-scroll";
import { Clear, TableRows } from "@mui/icons-material";

export const Header = () => {
  const [nav, setNav] = useState(false);

  const handleClick = () => setNav(!nav);

  const handleClose = () => setNav(!nav);
  return (
    <div className="w-full h-[60px] z-10 bg-[#191919] fixed drop-shadow-2xl">
      <div className="flex justify-evenly items-center max-w-[1240px] m-auto h-full">
        <div className="hidden md:flex w-full justify-center">
          <ul className="hidden md:flex justify-evenly w-[50%]  text-sm text-[#9BA4B5]">
            <li>
              <Link
                className="cursor-pointer hover:text-lime-400"
                to="home"
                smooth={true}
                duration={500}
              >
                <Tooltip title="Home">
                  <Home className="hover:drop-shadow-[0_0px_16px_rgba(255,255,255)]" />
                </Tooltip>
              </Link>
            </li>
            <li>
              <Link
                className="cursor-pointer hover:text-lime-400"
                to="about"
                smooth={true}
                duration={500}
                offset={-55}
              >
                <Tooltip title="About">
                  <InfoOutlined className="hover:drop-shadow-[0_0px_16px_rgba(255,255,255)]" />
                </Tooltip>
              </Link>
            </li>
            {/* <li>
              <Link
                className="cursor-pointer hover:text-lime-400"
                to="workExperience"
                smooth={true}
                duration={500}
                offset={-55}
              >
                <Tooltip title="Experiences">
                  <Laptop className="hover:drop-shadow-[0_0px_16px_rgba(255,255,255)]" />
                </Tooltip>
              </Link>
            </li> */}
            <li>
              <Link
                className="cursor-pointer hover:text-lime-400"
                to="projects"
                smooth={true}
                duration={500}
                offset={-55}
              >
                <Tooltip title="Projects">
                  <AccountTree className="hover:drop-shadow-[0_0px_16px_rgba(255,255,255)]" />
                </Tooltip>
              </Link>
            </li>
            <li>
              <Link
                className="cursor-pointer hover:text-lime-400"
                to="testimonial"
                smooth={true}
                duration={500}
                offset={-55}
              >
                <Tooltip title="Testimonial">
                  <Reviews className="hover:drop-shadow-[0_0px_16px_rgba(255,255,255)]" />
                </Tooltip>
              </Link>
            </li>
            <li>
              <Link
                className="cursor-pointer hover:text-lime-400"
                to="faq"
                smooth={true}
                duration={500}
                offset={-55}
              >
                <Tooltip title="FAQ">
                  <Quiz className="hover:drop-shadow-[0_0px_16px_rgba(255,255,255)]" />
                </Tooltip>
              </Link>
            </li>
          </ul>
        </div>

        <div className="md:hidden p-2 w-full">
          {!nav ? (
            <TableRows
              className="w-5 text-[#9BA4B5] cursor-pointer hover:text-lime-400"
              onClick={handleClick}
            />
          ) : (
            <Clear
              className="w-5  text-[#9BA4B5] cursor-pointer hover:text-lime-400"
              onClick={handleClick}
            />
          )}
        </div>

        {/* Side menu with bar */}
        <ul
          className={
            !nav
              ? "hidden"
              : "absolute top-12 py-6 h-screen bg-[#191919] text-gray-500 w-full px-8"
          }
        >
          <li className="border-b-2 p-5 border-[#9BA4B5]/10 w-full">
            <Link
              className="cursor-pointer hover:text-lime-400"
              to="home"
              onClick={handleClose}
              smooth={true}
              duration={500}
            >
              <div className="flex gap-2 items-center ">
                <Home />
                <div>Home</div>
              </div>
            </Link>
          </li>
          <li className="border-b-2 p-5 border-[#9BA4B5]/10 w-full">
            <Link
              className="cursor-pointer hover:text-lime-400"
              to="about"
              onClick={handleClose}
              smooth={true}
              duration={500}
              offset={-55}
            >
              <div className="flex gap-2 items-center ">
                <InfoOutlined />
                <div>About</div>
              </div>
            </Link>
          </li>
          {/* <li className="border-b-2 p-5 border-[#9BA4B5] w-full">
            <Link
              className="cursor-pointer hover:text-lime-400"
              to="workExperience"
              smooth={true}
              onClick={handleClose}
              duration={500}
              offset={-55}
            >
              <Laptop /> Experiences
            </Link>
          </li> */}
          <li className="border-b-2 p-5 border-[#9BA4B5]/10 w-full">
            <Link
              className="cursor-pointer hover:text-lime-400"
              to="projects"
              smooth={true}
              onClick={handleClose}
              duration={500}
              offset={-55}
            >
              <div className="flex gap-2 items-center ">
                <AccountTree />
                <div> Projects</div>
              </div>
            </Link>
          </li>
          <li className="border-b-2 p-5 border-[#9BA4B5]/10 w-full">
            <Link
              className="cursor-pointer hover:text-lime-400"
              to="testimonial"
              smooth={true}
              onClick={handleClose}
              duration={500}
              offset={-55}
            >
              <div className="flex gap-2 items-center ">
                <Reviews />
                <div> Testimonial</div>
              </div>
            </Link>
          </li>
          <li className="border-b-2 p-5 border-[#9BA4B5]/10 w-full">
            <Link
              className="cursor-pointer hover:text-lime-400"
              to="faq"
              smooth={true}
              onClick={handleClose}
              duration={500}
              offset={-55}
            >
              <div className="flex gap-2 items-center ">
                <Quiz />
                <div>FAQs</div>
              </div>
            </Link>
          </li>
          <li>
            <div className="flex justify-center gap-4 m-2 p-2 ">
              <a
                href="https://www.linkedin.com/in/sajjanshres"
                target="_blank"
                className="cursor-pointer hover:text-lime-400"
              >
                <LinkedIn fontSize="large" />
              </a>
              <a
                href="https://github.com/sajjanshres"
                target="_blank"
                className="cursor-pointer hover:text-lime-400"
              >
                <GitHub fontSize="large" />
              </a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};
