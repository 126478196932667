import React from "react";
import { Header } from "./components/header/Header";
import { Dashboard } from "./components/dashboard/Dashboard";
import About from "./components/about/About";
import { WorkExperience } from "./components/experiences/WorkExperience";
import { Projects } from "./components/projects/Projects";
import Testimonials from "./components/testimonials/Testimonials";
import Faqs from "./components/faqs/Faqs";
import { Footer } from "./components/footer/Footer";

const Landing = () => {
  return (
    <>
      <Header />
      <Dashboard />
      <About />
      {/* <WorkExperience /> */}
      <Projects />
      <Testimonials />
      <Faqs />
      <Footer />
    </>
  );
};

export default Landing;
