import React from "react";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";

const AlertMsg = ({ status, message }) => {
  return (
    <>
      <Stack sx={{ width: "100%" }} spacing={2}>
        <Alert severity={status} sx={{fontSize: '9pt'}}>{message}</Alert>
      </Stack>
    </>
  );
};

export default AlertMsg;
