import React, { useState } from "react";
import CardBox from "components/landing/components/projects/components/CardBox";
import ark from "assets/ark.webp";
import ims from "assets/ims.webp";
import ModalBox from "components/ModalBox";
import Display from "components/FramerDisplay";
import FramerAnimate from "components/FramerAnimate";
import FramerOpacity from "components/FramerOpacity";
import { Typography } from "@mui/material";

export const Projects = () => {
  const [projects, setProjects] = useState([
    {
      name: "ARK Railings",
      website: "https://www.arkrailings.com",
      image: ark,
      description: "A bookkeeping web application.",
      stack: [
        "Java",
        "Spring Boot",
        "Spring Security",
        "ReactJS",
        "PostgreSQL",
        "HTML",
        "CSS",
      ],
    },
    {
      name: "IMS Paperfly",
      website: "https://www.imspaperfly.com",
      image: ims,
      description: "An invoice management web application.",
      stack: [
        "Python",
        "Django Framework",
        "ReactJS",
        "PostgreSQL",
        "HTML",
        "CSS",
      ],
    },
  ]);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);

  return (
    <div name="projects" className="w-full bg-[#191919]">
      <FramerOpacity>
        <FramerAnimate>
          <div className="text-5xl max-w-[1240px] m-auto font-bold  p-8 text-[#191919] drop-shadow-[0px_2px_3px_rgb(255,255,255)]">
            <p>Projects</p>
          </div>
        </FramerAnimate>
        <div className="items-center flex justify-center max-w-[1240px] m-auto min-h-screen">
          <div className="items-center">
            <Display>
              <div className="flex justify-evenly sm:flex-wrap tablet:flex-col tablet:items-center w-full">
                <CardBox projects={projects} />
              </div>
            </Display>
            <FramerAnimate>
              <div className="py-6 px-2 text-center mt-11 bg-[#212A3E]/50 text-[#F1F6F9] sm:rounded-xl ">
                <Typography variant="body2" sx={{ color: "#F1F6F9", px: 1}}>
                  I help businesses grow by building small to medium-scale
                  websites end to end including the website's design, logo, etc.
                  as a freelancer.
                </Typography>
                <p className="text-2xl m-2 text-yellow-500 font-bold">
                  <span className="mt-2"> Need a quote?</span>
                </p>
                <Typography variant="body2" sx={{ color: "#F1F6F9", px: 1 }}>
                  Feel free to send me a message to discuss more about your
                  website requirements and pricing.
                </Typography>
                <div className="p-3">
                  <button
                    className="bg-orange-400 rounded-md text-[#212A3E] text-lg
                                px-2 w-full sm:w-[25%]  py-3 shadow-xl cursor-pointer hover:text-[#212A3E] hover:bg-[#F1F6F9]
              hover:font-bold hover:drop-shadow-[0_0px_9px_#fff] "
                    onClick={handleOpen}
                  >
                    Contact
                  </button>
                </div>
                <ModalBox open={open} setOpen={setOpen} />
              </div>
            </FramerAnimate>
          </div>
        </div>
      </FramerOpacity>
    </div>
  );
};
